import { displayFlashMessage, showLoadingMessage, hideLoadingMessage } from '../../util/methods';

$(document).on('turbolinks:load', function () {

  var $versionSelects = $('.js-version-select');

  if ($versionSelects.length) {

    $versionSelects.each(function() {
      let $this = $(this);

      $this.select2({
        width: '100%',
        minimumResultsForSearch: Infinity, // this is to hide the search bar
      });
      
      $this.on('change', function(){
        let $this = $(this);
        let version_id = $this.val();
        let $downLoadsList = $this.closest('.order-pickup-files').find('.js-downloads-list');
        
        showLoadingMessage('Fetching version downloads');
        
        $.ajax({
          url: $this.data('url'),
          method: 'get',
          dataType: 'json',
          data: { version_id: version_id },
          success: function(data) {
            hideLoadingMessage();
            $downLoadsList.html(data['html']);
          },
          error: function(xhr, status, error) {
            hideLoadingMessage();
            displayFlashMessage('danger', 'Failed to fetch version downloads');
            $downLoadsList.html('<small class="text-danger d-block font-italic">Failed to fetch version downloads</small>');
            console.log("Error: ", error);
          }
        });
      }); 
    });

    $(document).one('turbolinks:before-cache', function() {
      // destroy select2 dropdowns to prevent duplication on reload
      $versionSelects.each(function() {
        let $this = $(this);
        $this.select2('destroy');
      });
    });
  }

});
